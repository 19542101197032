import { Status } from '@mna/react';

export default () => (
  <div className="article-width">
    <div className="site-container">
      <main className="page d_board">
        <div className="container">
          <div className="dashboard home not-found">
            <Status code={404} />
            <h1>Page not found</h1>
          </div>
        </div>
      </main>
    </div>
  </div>
);
